import {DataGrid, GridColDef, GridRenderCellParams, GridRowParams} from '@mui/x-data-grid';
import {useMutationAsUser, useQueryAsUser} from '../../clients/graphql/graphql.hooks';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    List,
    ListItemButton,
    Typography
} from '@mui/material';
import React, {useState} from 'react';
import Box from "@mui/material/Box";
import {
    AutoPilotBrokerFeaturesGetQuery, FeatureAccessRequestCreateMutation,
    JediGetFeatureRequestsQuery
} from "../../clients/graphql/components/buttons-queries";
import {formatMoment} from "../../functions/momentFormatter";
import moment from "moment/moment";
import GrantRequestDialog from "../dialogs/GrantRequestDialog";
import DialogContent from "@mui/material/DialogContent";

const columns: GridColDef[] = [
    {
        headerName: 'Feature',
        field: 'featureName',
        headerClassName: 'table-header',
        headerAlign: 'center',
        minWidth: 280,
        flex: 1,
        align: 'center',
        renderCell: (params: GridRenderCellParams) => {
            return params.value.replace(/(AutoPilot Beta Feature)$/, '').trim();
        }
    },
    {
        headerName: 'Created At',
        field: 'createdAt',
        headerClassName: 'table-header',
        headerAlign: 'center',
        minWidth: 50,
        flex: 1,
        align: 'center',
        renderCell: (params) => {
            return formatMoment(params.value);
        },
    },
    {
        headerName: 'Approved At',
        field: 'approvedAt',
        headerClassName: 'table-header',
        headerAlign: 'center',
        minWidth: 50,
        flex: 1,
        align: 'center',
        renderCell: (params) => {
            const isValidDate = moment(params.value).isValid();
            return isValidDate ? formatMoment(params.value) : '';
        },
    },

];

interface BetaFeaturesTableProps {
    info: {
        userKey?: number;
    }
}

interface Request {
    featureAccessRequestKey: number;
    featureKey: number;
    featureName: string;
    createdAt: string;
    requestedAt: string;
    approvedAt: string;
    deniedAt: string;
    revokedAt: string | null;
}

interface Feature {
    name: string;
}

export const BetaFeaturesTable: React.FC<BetaFeaturesTableProps> = ({ info }) => {

    const { userKey } = info;
    const [selectedRow, setSelectedRow] = useState<Request | null>(null);


    const handleRowClick = (params: GridRowParams) => {
        const selectedTrigger = requests.find((item) => item.featureKey === params.row.featureKey);
        if (selectedTrigger) {
            setSelectedRow(null);
            setTimeout(() => setSelectedRow(selectedTrigger), 0);
        }
    };


    const FeaturesCard = ({feature}: { feature: Feature }) => {
        const [errorDialogOpen, setErrorDialogOpen] = useState(false);

        const providerMap: Record<string, string> = {
            "Fidelity": "FIDELITY_AUTO_PILOT",
            "Charles Schwab": "SCHWAB_AUTO_PILOT",
            "Merrill Edge": "MERILL_EDGE_AUTO_PILOT",
            "Interactive Brokers Group": "INTERACTIVE_AUTO_PILOT",
            "J.P. Morgan": "JP_MORGAN_AUTO_PILOT",
            "First Trade": "FIRST_TRADE_AUTO_PILOT",
            "E-Trade": "ETRADE_AUTO_PILOT",
            "Tastyworks": "TASTY_WORKS_AUTO_PILOT",
            "Vanguard": "VANGUARD_AUTO_PILOT",
            "International Broker": "INTERNATIONAL_AUTO_PILOT",
            "Public": "YODA_AUTO_PILOT"
        };

        const name = providerMap[feature?.name];

        const [featureAccessRequestCreateMutation, { data, loading, error }] = useMutationAsUser(FeatureAccessRequestCreateMutation,  {
            variables: {
                featureType: name,
            },
            userKey: userKey,
        });

        const handleAddFeature = async () => {
            try {
                await featureAccessRequestCreateMutation();
            } catch (err) {
                setErrorDialogOpen(true);
                console.error('Mutation Error:', err);
            }
        };

        return (
            <>
                {loading && (
                    <CircularProgress size={60} sx={{ marginTop: '80px', marginLeft: '50%' }} />
                )}

                <Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
                    <DialogContent>
                        <Typography sx={{ textAlign: 'center' }}>
                            {error?.message}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setErrorDialogOpen(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <ListItemButton
                    onClick={handleAddFeature}
                    sx={{
                        borderBottom: '1px solid lightgray',
                        marginX: '1rem',
                    }}>
                    <Typography sx={{fontWeight: 'light'}}>{feature?.name}</Typography>
                </ListItemButton>
            </>
        );
    };


    const FeaturesList = ({features}: { features: Feature[] }) => {
        return (
            <List>
                {features.map(feature => (<FeaturesCard feature={feature}/>))}
            </List>
        );
    };


    function BetaAccessButton({userKey}: { userKey: string }): JSX.Element {

        const [open, setOpen] = useState(false);
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);

        const {data: brokerData, loading: brokerloading} = useQueryAsUser(AutoPilotBrokerFeaturesGetQuery, {userKey});

        const features: Feature[] = brokerData?.autoPilotBrokerFeaturesGet?.features;
        if (brokerloading) {
            return <CircularProgress size={30} sx={{
                display: 'flex',
                justifySelf: "center",
                alignSelf: "center",
                margin: "auto",
                marginBottom: "10px"
            }}/>;
        }

        return(
            <>
                <Button
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                    color="primary"
                    onClick={handleOpen}
                >
                    Add Feature
                </Button>

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle sx={{ fontWeight: 'light' }}>Features</DialogTitle>
                    <FeaturesList features={features} />
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>);
    }


    const {data, loading, error} = useQueryAsUser(JediGetFeatureRequestsQuery, {userKey});

    if(loading) {
        return  (
            <CircularProgress size={60} sx={{ marginTop: '80px', marginLeft: '50%'}} />
        );
    }

    if(error) return <div>Error: {error?.message}</div>;

    const requests: Request[] = data?.jediGetFeatureRequests?.requests;


    let rows = requests.map((row, index) => {
        return {
            ...row,
            id: index,
            featureName: row?.featureName,
            approvedAt: row?.approvedAt,
            createdAt: row?.createdAt,
        }
    });


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '20rem',}}>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <Typography variant="subtitle1" component="div" align="left"
                             sx={{
                                 color: '#6D6D6D',
                                 font: 'Roboto',
                                 fontWeight: 500,
                                 fontSize: '13px',
                                 lineHeight: '12px',
                                 letterSpacing: '0.05em',
                                 paddingBottom: '.75rem',
                                 paddingTop: '.75rem',
                                 paddingRight: '.75rem',
                             }}>
                FEATURE REQUESTS
            </Typography>
                <BetaAccessButton userKey={userKey!.toString()}/></Box>
            <DataGrid
                rows={rows}
                columns={columns}
                onRowClick={handleRowClick}
                loading={loading}
                rowHeight={40}
                hideFooter={true}
                initialState={{
                    sorting: {
                        sortModel: [{field: 'approvedAt', sort: 'desc',}],
                    },
                }}
            />
            {selectedRow && selectedRow.approvedAt == null && (
                <GrantRequestDialog request={selectedRow} onClose={() => setSelectedRow(null)}/>
            )}
        </Box>
    );
};
