import React from 'react';
import '../css/slave-portfolio-card-style.css';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Tooltip} from "@mui/material";

const SlavePortfolioCard = ({ title, subtitle, amount, change,isPendingTransfer, virtualText, isLosingMoney, onClick }) => {

    return <div className="card" style={{ backgroundColor: '#191919', border: '1px solid gray' }} onClick={onClick}>
        <Box sx={{
            flexDirection: 'column',
            display: 'flex',
            margin: '0.5rem',
        }}>
                <Box sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '100%',
                }}>
                    {/*Card Amount*/}
                    <Typography fontWeight={"bold"}  fontSize={'1vw'} style={{ color: isPendingTransfer ? 'gray' : 'white' }}>
                        ${(amount ?? 0.0).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).slice(1)}</Typography>
                    {/*Pilot*/}
                    <Tooltip title={title}>
                        <Typography sx={{
                            whiteSpace: "nowrap",  // Prevents wrapping
                            overflow: "hidden",
                            fontSize: "1vw",
                            textOverflow: "ellipsis",
                            marginLeft: '1rem',
                            }}>{title}
                        </Typography>
                    </Tooltip>
                </Box>

            <Box sx={{
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'left',
            }}>
                <Box sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    }}>
                {/*Percent Change*/}
                <Typography fontSize={'1vw'} style={{color: isLosingMoney ? 'red' : 'green'}}>
                    {isPendingTransfer ? 'Deposit Pending' : change}
                </Typography>
                <Typography sx={{
                    fontSize: '0.8em',
                    userSelect: 'none',
                }}>{virtualText}</Typography>
            </Box>
                <Typography sx={{
                    fontSize: '0.8em',
                    userSelect: 'none',
                }}>{subtitle}
                </Typography>
            </Box>
            </Box>
            <Box sx={{
                textAlign: 'start',
                userSelect: 'none',
                paddingBottom: '0.5rem',
                paddingLeft: '0.5rem',
            }}>
        </Box>
    </div>;
};

export default SlavePortfolioCard;
