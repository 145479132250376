import {Button, CircularProgress, Dialog} from "@mui/material";
import React, { useState } from "react";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import {useMutationCoordinate} from "../../clients/graphql/graphql.hooks";
import Typography from "@mui/material/Typography";
import {JediGrantFeatureRequestMutation} from "../../clients/graphql/components/buttons-queries";

interface Request {
    featureAccessRequestKey: number;
    featureKey: number;
    featureName: string;
    createdAt: string;
    requestedAt: string;
    approvedAt: string;
    deniedAt: string;
    revokedAt: string | null;
}

const FailureDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <Typography sx={{ textAlign: 'center' }}>
                    The beta request was not granted. Please try again later.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default function GrantRequestDialog({ request, onClose}: { request: Request, onClose: () => void }) {

    const [input, setInput] = useState('');
    const [grantSuccess, setGrantSuccess] = useState(false);
    const [open, setOpen] = useState(true);
    const [failureDialogOpen, setFailureDialogOpen] = useState(false);

    const [grantRequestMutation, status] = useMutationCoordinate(JediGrantFeatureRequestMutation, { variables: { featureAccessRequestKey: request?.featureAccessRequestKey }});

    function grantRequest(input: string) {
        if (input === 'grant request') {
            grantRequestMutation()
                .then((response) => {
                    if (response?.data?.jediGrantFeatureRequest?.success) {
                        setGrantSuccess(true);
                        setOpen(true);
                    } else {
                        setFailureDialogOpen(true);
                    }
                })
                .catch((error) => {
                    console.error('Mutation Error:', error);
                });
            setInput('');
        } else {
            setInput('');
            setOpen(false);
            onClose();
        }
    }

    return (
        <div>
            {open && (<Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContentText sx={{
                    alignItems: "center",
                    width: 'auto',
                    padding: '1rem',
                }}>Type 'grant request' to grant beta request for '{request?.featureName}'.
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Confirm beta request"
                        autoComplete={'grant request'}
                        onChange={(e) => setInput(e.target.value)}
                        value={input}
                        autoFocus
                    /></DialogContentText>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Nevermind</Button>
                    <Button onClick={() => {
                        grantRequest(input);
                        setInput('');
                    }} autoFocus style={{color: "green"}}>
                        Grant
                    </Button>
                </DialogActions>
            </Dialog>
            )}

            {status?.loading && (
                <Dialog
                    open={status?.loading}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <CircularProgress size={60} sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", margin: "auto"}} />
                    </DialogContent>
                </Dialog>
            )}

            <FailureDialog open={failureDialogOpen} onClose={() => setFailureDialogOpen(false)} />

            {grantSuccess && (
                <Dialog
                    open={open}
                >
                    <DialogContent>
                        <Typography sx={{margin: "auto"}}>
                            Beta request for <strong>{request?.featureName}</strong> successfully granted!
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
