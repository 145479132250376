import {Alert, CircularProgress, Divider, List, ListItem, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import {useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import moment from "moment/moment";
import {getInstitutionDetails} from "../../clients/graphql/views/institutions-queries";
import {formatMoment} from "../../functions/momentFormatter";

interface props{
    institutionKey: number
}

export function InstitutionTimestampsPanel({institutionKey}: props) {

    const {loading, error, data} = useQueryCoordinate(getInstitutionDetails, {variables: {institutionKey: institutionKey}});

    if (loading) {
        return <CircularProgress/>
    }

    if (error) {
        return <Alert severity="warning">{error?.message}</Alert>
    }
    const curatedData = data?.whoisit?.institution;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start' ,
            paddingBottom: "1rem",
            borderRadius: '8px',
            height: '21rem',
        }}>
            <Typography variant="subtitle1" component="div" align="left"
                        sx={{
                            color: '#6D6D6D',
                            font: 'Roboto',
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '12px',
                            letterSpacing: '0.05em',
                            paddingLeft: '1rem',
                            paddingTop: '.75rem',
                        }}>
                AUTHENTICATION DATA
            </Typography>
                <List>
                    <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography>MFA Type</Typography>
                        <Typography fontWeight={'light'}>{curatedData?.mfaTyp ?? "Undefined"}</Typography>
                    </ListItem>
                    <Divider sx={{ my: 1 }} />
                    <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography>Access Token Expiration</Typography>
                        <Typography fontWeight={'light'}>{formatMoment(curatedData?.gzpcExpiresAt)}</Typography>
                    </ListItem>
                    <Divider sx={{ my: 1 }} />
                    <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography>Backup Withholding</Typography>
                        <Typography fontWeight={'light'}>{(curatedData?.hasBackupTaxWithholding ?? 'Undefined')}</Typography>
                    </ListItem>
                </List>
            </Box>
    )
}