import React, { useState } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useNavigate } from 'react-router-dom';
import {Logout, ToggleOff, ToggleOn} from '@mui/icons-material';
import { AutopilotSearchBox } from '../../components/search/AutopilotSearchBox';
import { TestProdToggle } from '../../components/buttons/TestProdToggle';
import Divider from "@mui/material/Divider";

export function HeaderBar() {

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const menuOpen = Boolean(anchorEl);
  const [isUTC, setIsUTC] = useState(() => {
    return localStorage.getItem("isUTC") === "true";
  });

  const navigator = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUTCClick = () => {
    setIsUTC((prev) => {
      const newValue = !prev;
      localStorage.setItem("isUTC", newValue.toString());
      window.location.reload()
      return newValue;
    });
  };

  return <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
    <Toolbar sx={{ backgroundColor: '#121212' }}>
      <div onClick={(e) => {
        if (e.metaKey || e.ctrlKey) {
          window.open(`/`);
        } else {
          navigator(`/`)}
      }} style={{cursor: 'pointer', display: 'flex', alignContent: 'left', alignItems: 'center'}}>
        <img src="/wheel-icon.png" style={{ height: '1.8rem' }} alt="Logo" />
        <Typography variant="h6" noWrap component="div" sx={{ marginLeft: '10px' }}>
          Autopilot Admin
        </Typography>
      </div>
      <Box sx={{ flexGrow: 1 }} />
      <Typography variant="body1" noWrap component="div" sx={{ marginLeft: '10px' }}>
        Customer Search:
      </Typography>
      <AutopilotSearchBox />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ width: '40%', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-around'}}>
        <div onClick={(e) => {
          if (e.metaKey || e.ctrlKey) {
            window.open(`/pilots`);
          } else {
            navigator(`/pilots`)}
        }}
             style={{cursor: 'pointer', display: 'flex', alignContent: 'left', alignItems: 'center'}}>
          <Typography variant="h6" noWrap component="div" sx={{ marginLeft: '10px' }}>
            Pilots
          </Typography>
        </div>
        <div onClick={(e) => {
          if (e.metaKey || e.ctrlKey) {
            window.open(`/assets/CHANGEME`);
          } else {
            navigator(`/assets/CHANGEME`)}
        }}
          style={{cursor: 'pointer', display: 'flex', alignContent: 'left', alignItems: 'center'}}>
          <Typography variant="h6" noWrap component="div" sx={{ marginLeft: '10px' }}>
            Assets
          </Typography>
        </div>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box>
        <TestProdToggle />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton onClick={handleClick} size="small">
            <Avatar sx={{ width: 40, height: 40 }}><PersonOutlineIcon /></Avatar>
          </IconButton>
        </Tooltip>
        <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleUTCClick}>
            <ListItemIcon>
              {isUTC ? <ToggleOn fontSize="medium" color={'primary'}/> : <ToggleOff fontSize="medium" />}
            </ListItemIcon>
            Toggle UTC
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Logout fontSize="medium" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </Toolbar>
  </AppBar>;
}