import React from 'react';
import {Alert, Box, Button, CircularProgress, Divider, List, ListItem, Typography} from '@mui/material';
import {useQueryCoordinate} from '../../clients/graphql/graphql.hooks';
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import {useNavigate} from "react-router-dom";
import {CONNECTION_STATUS} from '../../shared/portfolio-types';
import {DisconnectedStatus} from "../../components/status/DisconnectedStatus";
import {ConnectedStatus} from "../../components/status/ConnectedStatus";
import {KycStatusChip} from "../../components/status/kycStatus";
import {getInstitutionDetails} from "../../clients/graphql/views/institutions-queries";
import {formatMoment} from "../../functions/momentFormatter";

export type props = {
    institutionKey: number
    userKey: number
}

const capitalizeFirstLetter = (text: string): string => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export default function InstitutionCrudPanel({institutionKey, userKey}: props) {

    const goTo = useNavigate()

    const {loading, error, data} = useQueryCoordinate(getInstitutionDetails, {userKey: userKey, variables: {institutionKey: institutionKey}});

    if (loading) {
        return <CircularProgress/>
    }

    if (error) {
        return <Alert severity="warning">{error?.message}</Alert>
    }
    const curatedData = data?.whoisit?.institution;

    return(
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', borderRadius: '8px', height: '21rem'}}>
            <Typography variant="subtitle1" component="div" align="left"
                        sx={{
                            color: '#6D6D6D',
                            font: 'Roboto',
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '12px',
                            letterSpacing: '0.05em',
                            paddingLeft: '1rem',
                            paddingTop: '.75rem',
                        }}>
                OVERVIEW
            </Typography>
            <List>
                <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>{curatedData?.user?.firstName} {curatedData?.user?.lastName}</Typography>
                    <Button
                        size={'small'}
                        role={undefined}
                        variant="outlined"
                        color='primary'
                        endIcon={<ArrowForwardOutlinedIcon />}
                        onClick={(e) => {
                            if (e.metaKey || e.ctrlKey) {
                                window.open(`/users/${curatedData?.userKey}`);
                            } else {
                                goTo(`/users/${curatedData?.userKey}`)}
                        }}
                    >
                        #{curatedData?.userKey}
                    </Button>
                </ListItem>
                <Divider sx={{ my: 1 }} />
                <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>Status</Typography>
                    {curatedData?.connectionStatus === CONNECTION_STATUS.CONNECTED ? <ConnectedStatus /> : <DisconnectedStatus />}
                </ListItem>
                <Divider sx={{ my: 1 }} />
                <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>Institution</Typography>
                    <Typography fontWeight={'lighter'}>{capitalizeFirstLetter(curatedData.institutionName)}</Typography>
                </ListItem>
                <Divider sx={{ my: 1 }} />
                <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>Created At</Typography>
                    <Typography fontWeight={'lighter'}>{formatMoment(curatedData?.createdAt)}</Typography>
                </ListItem>
                <Divider sx={{ my: 1 }} />
                <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>KYC Status:</Typography>
                    <Typography variant="body1" display="flex" alignItems="center">
                        {curatedData?.kycCheck === null ? (
                            <KycStatusChip kycState={'NEED_KYC'} userKey={userKey} institutionKey={curatedData?.institutionKey} />
                        ) : (
                            <KycStatusChip kycState={curatedData?.kycCheck?.kycState} userKey={userKey} institutionKey={curatedData?.institutionKey} />
                        )}
                    </Typography>
                </ListItem>
            </List>
        </Box>
    )
}