import {useQueryCoordinate} from '../../clients/graphql/graphql.hooks';
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import DoneIcon from '@mui/icons-material/Done';
import AlertIcon from '@mui/icons-material/NotificationImportant';
import {CircularProgress, Tooltip, Typography} from '@mui/material';
import * as _ from 'lodash';
import moment from 'moment';
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import {institutionAuthActivitiesGet, institutionAuthActivity} from "../../clients/graphql/components/tables-queries";
import {formatMoment} from "../../functions/momentFormatter";

interface Props {
    portfolioKey?: number;
    institutionKey?: number;
};

export const InstitutionAuthLogsTable: React.FC<Props> = ({ institutionKey }) => {
    const { data, loading, error } = useQueryCoordinate(institutionAuthActivitiesGet, { variables: { institutionKey, limit: 100, offset: 0 } });
    const activities = data?.institutionAuthActivitiesGet?.institutionAuthActivities;

    if (loading) {
        return <CircularProgress sx={{marginLeft: 'calc(50% - 10px)'}}/>
    }

    if (error) {
        return <div>Error fetching data. Please reload page</div>;
    }

    const columns: GridColDef[] = [
        { 
            field: 'success', 
            headerName: 'Success', 
            width: 70,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tooltip title={params.row.errorMessage}>
                        {params.row.success ? <DoneIcon color='success' style={{verticalAlign: 'middle'}} /> : <AlertIcon color='error' style={{verticalAlign: 'middle'}} />}
                    </Tooltip>
                );
            }
        },
        { 
            field: 'activityType', 
            headerName: 'Activity Type',
            width: 170,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        {params.value && <span>{params.value.split('_').map(_.capitalize).join(' ')}</span>}
                    </div>
                );
            }
        },
        { field: 'createdAt', headerName: 'Created At', width: 190, valueFormatter: (value) => value ? formatMoment(value) : ''},
        { field: 'note', headerName: 'Source', width: 150,
            renderCell: (params: GridRenderCellParams) =>
                (
                    <Tooltip title={params.value || ''} placement={"top"}>
                        <span
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'inline-block',
                                maxWidth: '100%',
                            }}
                        >
                            {params.value}
                        </span>
                    </Tooltip>
        )
        },
        {
            field: 'nextExpiresAt',
            headerName: 'Expires At',
            width: 148,
            valueFormatter: (value) => value ? formatMoment(value) : ''
        },
        {
            field: 'errorMessage',
            headerName: 'Error Message',
            width: 148,
            renderCell: (params: GridRenderCellParams) => <span color='red'>{params.value}</span>
        },
        {   field: 'data',
            headerName: 'Data',
            width: 70,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tooltip title={params.row.data}>
                        <InfoIcon style={{verticalAlign: "middle"}} />
                    </Tooltip>
                );
            }
        },
    ];

    const rows = activities.map((activity: institutionAuthActivity) => {
        return {
            id: activity.createdAt,
            activityType: activity.activityType,
            createdAt: activity.createdAt,
            note: activity.note,
            success: activity.success,
            errorMessage: JSON.parse(activity.data)?.error,
            data: activity.data,
            nextExpiresAt: JSON.parse(activity.data)?.refreshTokenExpiresAt
        };
    });

    return (
        <Box sx={{height: '28.5rem'}}>
            <Typography sx={{
                color: '#6D6D6D',
                font: 'Roboto',
                fontWeight: 500,
                fontSize: '13px',
                lineHeight: '12px',
                letterSpacing: '0.05em',
                marginTop: '1rem',
                marginLeft: '1rem',
                paddingBottom: '.75rem',
            }}>
                AUTH LOGS
            </Typography>
            <DataGrid 
                rowHeight={40}
                columns={columns} 
                rows={rows} 
                loading={loading}
                hideFooter={true}
                sx = {{marginLeft: '1rem', marginRight: '1rem', height: '26rem'}}
            />
        </Box>
    );
};