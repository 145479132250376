import {Alert, Box, Button, Chip, CircularProgress, Typography} from "@mui/material";
import React from "react";
import {styled} from "@mui/material/styles";
import {useQueryAsUser} from "../../clients/graphql/graphql.hooks";
import {useNavigate} from "react-router-dom";
import {whoIsIt} from "../../clients/graphql/views/portfolio-queries";
import {formatMoment} from "../../functions/momentFormatter";

const InformationCard = styled(Box)(() => ({
    marginLeft: '.75rem',
    marginTop: '.5rem',
    marginRight: '.75rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: '1px solid rgba(18, 18, 18, 0.15)',
    borderRadius: '5px',
    fontFamily: 'Roboto',
    letterSpacing: '0.05em',
    textAlign: 'left',
}));

interface PortfolioCrudPanelProps {
    portfolioKey: number;
    userKey: number;
    deletedAt: string;
}

export default function PortfolioCrudPanelREDESIGN({portfolioKey, userKey, deletedAt}: PortfolioCrudPanelProps) {

    const goTo = useNavigate();
    const {loading, error, data} = useQueryAsUser(whoIsIt, {variables: {portfolioKey: portfolioKey}});

    if (loading) {
        return <CircularProgress sx={{ marginLeft: 'calc(50% - 10px)', marginTop: '1rem' }} />;
    }

    if (error) {
        return <Alert severity="warning">{error?.message}</Alert>;
    }
    const curatedPortfolioData = data?.whoisit?.portfolio?.financialInfo;
    const curatedInstitutionData = data?.whoisit?.institution;

    return(
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' , height: '32.9rem'}}>
            <Typography variant="h6" component='div' sx={{
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '12px',
                paddingTop: '.75rem',
                paddingLeft: '.75rem'
            }}>
                TOTAL VALUE
            </Typography>

            <Typography variant="h6" component='div' sx={{
                fontFamily: 'Roboto Mono',
                fontSize: '32px',
                fontWeight: 400,
                lineHeight: '42.69px',
                color: 'black',
                paddingLeft: '.75rem',
                paddingTop: '.75rem',

            }}>
                {curatedPortfolioData?.totalValue?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
            </Typography>

            <Typography variant="h6" component='div' sx={{
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '12px',
                letterSpacing: '0.05em',
                paddingTop: '1.5rem',
                paddingLeft: '.75rem'
            }}>
                BUYING POWER
            </Typography>

            <Typography variant="h6" component='div' sx={{
                fontFamily: 'Roboto Mono',
                fontSize: '32px',
                fontWeight: 400,
                lineHeight: '42.69px',
                color: 'black',
                paddingLeft: '.75rem',
                paddingTop: '.75rem',

            }}>
                {curatedPortfolioData?.buyingPower?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="h6" component='div' sx={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '12px',
                    color: '#6D6D6D',
                    paddingTop: '1rem',
                    paddingLeft: '.75rem',
                }}>
                    LAST CHECKED:
                </Typography>

                <Typography variant="h6" component='div' sx={{
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '12px',
                    color: 'black',
                    paddingTop: '1rem',
                    paddingLeft: '.75rem',
                    paddingRight: '.5rem'
                }}>
                    {formatMoment(curatedPortfolioData?.lastHardCheckAt)}
                </Typography>
            </Box>

            <Typography variant="h6" component='div' sx={{
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '12px',
                letterSpacing: '0.05em',
                paddingTop: '1.5rem',
                paddingLeft: '.75rem'
            }}>
                INFORMATION
            </Typography>

            <InformationCard>
                <Typography variant="h6" component='div' sx={{
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontWeight: 400,
                    lineHeight: '21.34px',
                    paddingLeft: '.75rem',
                    marginTop: '.75rem'
                }}>
                    User
                </Typography>

                <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    sx={{
                        margin: '.5rem'
                    }}
                    onClick={(e) => {
                        if (e.metaKey || e.ctrlKey) {
                            window.open(`/users/${userKey}`);
                        } else {
                            goTo(`/users/${userKey}`)}
                        }
                }
                >
                    #{userKey}
                </Button>
            </InformationCard>

            <InformationCard>
                <Typography variant="h6" component='div' sx={{
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontWeight: 400,
                    lineHeight: '21.34px',
                    paddingLeft: '.75rem',
                    marginTop: '.75rem'
                }}>
                    Status
                </Typography>

                {data?.whoisit?.portfolio?.connectionStatus === 'CONNECTED' && (
                    <Chip sx={{
                        margin: '.5rem'
                    }} label="Connected" color="success" />
                )}

                {data?.whoisit?.portfolio?.connectionStatus === 'DISCONNECTED' && (
                    <Chip sx={{
                        margin: '.5rem'
                    }} label="Disconnected" color="error" />
                )}

            </InformationCard>

            <InformationCard>
                <Typography variant="h6" component='div' sx={{
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontWeight: 400,
                    lineHeight: '21.34px',
                    paddingLeft: '.75rem',
                    marginTop: '.75rem'
                }}>
                    Institution Key
                </Typography>

                <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    sx={{
                        margin: '.5rem'
                    }}
                    onClick={(e) => {
                        if (e.metaKey || e.ctrlKey) {
                            window.open(`/institution/${curatedInstitutionData?.institutionKey}`);
                        } else {
                            goTo(`/institution/${curatedInstitutionData?.institutionKey}`)}
                    }}>
                    #{curatedInstitutionData?.institutionKey}
                </Button>
            </InformationCard>

            <InformationCard>
                <Typography variant="h6" component='div' sx={{
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontWeight: 400,
                    lineHeight: '21.34px',
                    paddingLeft: '.75rem',
                    marginTop: '.75rem',
                    paddingBottom: '.65rem',
                }}>
                    Created At
                </Typography>

                <Typography variant="h6" component='div' sx={{
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '18px',
                    marginTop: '.75rem',
                    paddingRight: '.5rem',
                    color: '#4E4E4E'
                }}>
                    {formatMoment(curatedInstitutionData?.createdAt)}
                </Typography>
            </InformationCard>

            <InformationCard>
                <Typography variant="h6" component='div' sx={{
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontWeight: 400,
                    lineHeight: '21.34px',
                    paddingLeft: '.75rem',
                    marginTop: '.75rem',
                    paddingBottom: '.65rem',
                }}>
                    Deleted At
                </Typography>

                <Typography variant="h6" component='div' sx={{
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '18px',
                    marginTop: '.75rem',
                    paddingRight: '.5rem',
                    color: '#4E4E4E'
                }}>
                    {deletedAt === null ? 'N/A' : deletedAt}
                </Typography>
            </InformationCard>

        </Box>
    )
}