import {Box, Grid, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import { useQueryCoordinate } from "../../clients/graphql/graphql.hooks";
import {styled} from "@mui/material/styles";
import PortfolioCrudPanelREDESIGN from "../../views/portfolios/PortfolioCrudPanelREDESIGN";
import UserHistoricalValueChart from "./UserHistoricalValueChart";
import {PortfolioPositionsTable} from "../../components/tables/PortfolioPositionsTable";
import {InstitutionAuthLogsTable} from "../../components/tables/InstitutionAuthLogsTable";
import {FailedAutopilotOrdersTable} from "../../components/tables/FailedAutopilotOrdersTable";
import {whoisit} from "../../clients/graphql/pages/users-queries";
import {LegacyAdminButton} from "../../components/buttons/LegacyAdminButton";

const Item = styled(Box)(() => ({
    border: '1px solid rgba(18, 18, 18, 0.15)',
    borderRadius: '5px',
    marginTop: 10,
}));

export default function UserPortfolioReDesign() {
    const { portfolioKey } = useParams();
    const intPortfolioKey = parseInt(portfolioKey as string);
    const { data, loading, error } = useQueryCoordinate(whoisit, { variables: { portfolioKey: intPortfolioKey } });

    if (loading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error fetching data. Please reload page</div>;
    }

    const userKey = data?.whoisit?.user?.userKey;
    const parsedUserKey = parseInt(userKey as string);
    const institutionKey = parseInt(data?.whoisit?.institution?.institutionKey as string);

    return(
        <Box>
            <Typography variant="h4" paddingBottom={2}>
                Portfolio&nbsp;
                <span style={{color: 'grey'}}>
                    #{portfolioKey}
                </span>
            </Typography>
            <LegacyAdminButton path={`/portfolios/${portfolioKey}`} />
            <Grid container spacing={2}>
                <Grid item xs={2.5}>
                    <Item>
                        <PortfolioCrudPanelREDESIGN portfolioKey={intPortfolioKey} userKey={userKey} deletedAt={data?.whoisit?.user?.deletedAt}/>
                    </Item>
                </Grid>
                <Grid item xs={4.5}>
                    <Item sx={{ height: '33rem' }}>
                        <UserHistoricalValueChart portfolioKey={intPortfolioKey}/>
                    </Item>
                </Grid>
                <Grid item xs={5}>
                    <Item sx={{ height: '33rem' }}>
                        <InstitutionAuthLogsTable institutionKey={institutionKey} />
                    </Item>
                </Grid>
                <Grid item xs={7}>
                    <Item >
                        <PortfolioPositionsTable userKey={parsedUserKey} portfolioKey={intPortfolioKey} />
                    </Item>
                </Grid>
                <Grid item xs={5}>
                    <Item sx={{paddingLeft: '1rem', paddingRight: '1rem'}}>
                        <FailedAutopilotOrdersTable userKey={parsedUserKey} portfolioKey={intPortfolioKey} sx={{marginTop: '5rem', marginBottom: '1rem', paddingTop: '.5rem', marginLeft: '1rem'}} />
                    </Item>
                </Grid>
            </Grid>
        </Box>
    )
}