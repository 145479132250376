import Typography from "@mui/material/Typography";
import React from "react";
import {Box, Button, Divider, List, ListItem} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

type userInstitutionPageProps = {
    portfolios?: Portfolio[];
}

type Portfolio = {
    portfolioKey: number
    portfolioName: string
}

export function InstitutionPortfoliosList({portfolios = []}: userInstitutionPageProps) {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start' ,
            paddingBottom: "1rem",
            borderRadius: '8px',
            height: '21rem',
        }}>
            <Typography variant="subtitle1" component="div" align="left"
                        sx={{
                            color: '#6D6D6D',
                            font: 'Roboto',
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '12px',
                            letterSpacing: '0.05em',
                            paddingLeft: '1rem',
                            paddingTop: '.75rem',
                        }}>
                PORTFOLIOS
            </Typography>
                <List>
                    {
                        portfolios.map( (portfolio : Portfolio) => (
                            <PortfolioDisplay portfolio={portfolio}/>
                        ))
                    }
                </List>
        </Box>
    );
}

type PortfolioDisplayProps = {
    portfolio: Portfolio
}

function PortfolioDisplay({portfolio}: PortfolioDisplayProps) {

    const goTo = useNavigate()

    return(
        <>
            <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>{portfolio.portfolioName}</Typography>
                <Button
                    size={'small'}
                    component="label"
                    role={undefined}
                    variant="outlined"
                    color='primary'
                    endIcon={<ArrowForwardOutlinedIcon />}
                    onClick={(e) => {
                        if (e.metaKey || e.ctrlKey) {
                            window.open(`/portfolios/${portfolio.portfolioKey}`);
                        } else {
                            goTo(`/portfolios/${portfolio.portfolioKey}`)}
                    }}
                >
                    #{portfolio.portfolioKey}
                </Button>
            </ListItem>
            <Divider sx={{my: 1}}/>
        </>
);
}
