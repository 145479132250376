import React from 'react';
import './css/home-screen-style.css';
import SlavePortfolioCard from './components/slave-portfolio-card';
import SlavePortfolioHeader from './components/slave-portfolio-header';
import {Box, CircularProgress, Stack, Typography} from '@mui/material';
import {useQueryAsUser} from '../../../clients/graphql/graphql.hooks';
import {SlaveDetailScreen} from "../detail/slave-detail-screen";
import {getSlavePortfoliosQuery} from "../../../clients/graphql/pages/users-queries";
import {allPerformanceSpansGetQuery} from "../../../clients/graphql/views/mobile-queries";

export const HomeScreen = ({ userKey, setParams, onChangeOptionHome, currentOptionHome, slavePortfolio, slaveDataByAutopilotSetting, onDialogClose}) => {
    const { data: dataSlave, loading: loadingSlave, error: errorSlave } = useQueryAsUser(getSlavePortfoliosQuery, { userKey, variables: { userKey, appVersionEnabled: true, } });
    const { data: dataPerformance, loading: loadingPerformance, error: errorPerformance } = useQueryAsUser(allPerformanceSpansGetQuery, { userKey, appVersionEnabled:true , variables: { userKey } });

    const slavePerformanceData = dataPerformance ? dataPerformance[currentOptionHome].slavePerformance : null;

    const handleCardClick = (slave, dataPerformance) => {
            setParams(slave, dataPerformance);
    };

    return (
        <Box sx={{
            margin: '0.5rem',
        }}>
            <Typography variant="subtitle1" align={"left"}>CUSTOMER VIEW</Typography>
            <Box sx={{
                height: '20rem',
            }}>
                {loadingPerformance ? (
                    <div>Loading</div>
                ) : errorPerformance ? (
                    <div>Some error happens</div>
                ) : (
                    <SlavePortfolioHeader
                        isLoading={loadingPerformance}
                        isError={errorPerformance}
                        data={dataPerformance}
                        currentOption={currentOptionHome}
                        onChangeOption={onChangeOptionHome}
                        dataSlave={dataSlave?.slaveAutoPilotPortfoliosGet}
                    />
                )}
                <SlaveDetailScreen
                    slavePortfolio={slavePortfolio}
                    slaveDataByAutopilotSetting={slaveDataByAutopilotSetting}
                    onDialogClose={onDialogClose}
                />
                <Stack spacing={-4} sx={{
                    marginTop: '1rem',
                    overflowY: "auto", // Enables scrolling
                    height: '47rem'
                    }}>
                        {loadingSlave ? (
                            <CircularProgress size={40} sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", margin: "auto", marginBottom: "10px"}} />
                        ) : errorSlave ? (
                            <div style={{marginTop: '10.5rem', color: 'red'}}>Failure to fetch portfolios</div>
                        ) : (
                            dataSlave?.slaveAutoPilotPortfoliosGet.slaveAutoPilotPortfolios.map((slave, index) => {
                                const slaveDataByAutopilotSetting = slavePerformanceData?.find(
                                    (f) => f.autoPilotSettingsKey === slave.autoPilotSettingsKey
                                );
                                const result = slaveDataByAutopilotSetting
                                    ? `${slaveDataByAutopilotSetting?.profitLoss?.toFixed(2)}  (${(slaveDataByAutopilotSetting?.profitLossPercent * 100).toFixed(1)}%)`
                                    : '0.00 + (0.00%)';

                                return (
                                    <div
                                        key={index}
                                        onClick={() => handleCardClick(slave, dataPerformance)}
                                    >
                                        <SlavePortfolioCard
                                            title={slave.masterPortfolio.userFullName}
                                            subtitle={(slave.autoPilotSettings.setupStatus === 'INITIATED') ? 'FREE PLAN' : ''}
                                            amount={(slave.pendingTransferData?.amount ?? 0.0) !== 0.0 ? (slave.pendingTransferData?.amount) : slave?.totalValue}
                                            isPendingTransfer={slave.pendingTransferData?.amount ?? 0.0}
                                            change={result}
                                            isLosingMoney={slaveDataByAutopilotSetting?.profitLoss < 0}
                                            virtualText={slave.autoPilotSettings.slavePortfolio.portfolioName}
                                        />
                                    </div>
                                );
                            })
                        )}
                </Stack>
            </Box>
        </Box>
    );
};
