import React, {useState} from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    TextField,
    Typography
} from '@mui/material';
import {useMutationAsUser, useMutationCoordinate, useQueryAsUser} from '../../clients/graphql/graphql.hooks';
import EditIcon from '@mui/icons-material/Edit';
import {UserInstitutionsList} from "../institutions/UserInstitutionsList";
import Divider from "@mui/material/Divider";
import {
  GQL_SubscriptionPaymentInfoGet,
  GQL_UserEdit,
  GQL_WhoamiWithSubscriptions,
  WhoAmIBasic,
  WhoAmIWithSubscriptions
} from "../../clients/graphql/views/user-queries";
import {GQL_JediUserEdit} from "../../clients/graphql/pages/users-queries";
import {formatMoment} from "../../functions/momentFormatter";

export type UserCrudProps = {
    userKey: number
}
export function UserCrudPanel({ userKey }: UserCrudProps) {

    const [editing, setEditing] = useState<boolean>(false);
    const { loading, error, data, refetch } = useQueryAsUser(GQL_WhoamiWithSubscriptions, { userKey, variables: { userKey } })


    return <>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography variant="subtitle1" component="div" align="left"
                        sx={{
                            color: '#6D6D6D',
                            font: 'Roboto',
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '12px',
                            letterSpacing: '0.05em',
                            paddingLeft: '.75rem',
                            paddingTop: '.75rem',
                            paddingBottom: '.25rem',
                        }}>
                DETAILS
            </Typography>
            <Button
                color="inherit"
                onClick={() => setEditing(true)}
                sx={{
                    minWidth: 'auto',
                    minHeight: 'auto',
                    height: '10'
                }}>
                <EditIcon fontSize='small'/>
            </Button>
        </Box>
        {
            loading ? <CircularProgress  sx={{ marginLeft: '40%', marginTop: '50px'}}/> :
                error ? <Alert severity="warning">{error?.message}</Alert> :
                    <NewUserCrudDisplay userKey={userKey} whoami={data?.whoami} />
        }
        {data &&
            <UserUpdateDialog isOpen={editing} handleClose={() => { setEditing(false); refetch(); }}
                              whoami={data?.whoami} userKey={userKey} />
        }
    </>
}

type NewUserCrudDisplayProps = {
    userKey: number,
    whoami: WhoAmIWithSubscriptions
}

function NewUserCrudDisplay({ userKey, whoami }: NewUserCrudDisplayProps) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' , paddingBottom: "1rem", borderRadius: '8px'}}>
                <Box sx={{
                    textAlign: 'left',
                    borderRadius: '8px',
                    marginLeft: '.75rem',
                    marginRight: '.75rem',
                    overflowX: 'scroll',
                    whiteSpace: 'nowrap',
                    marginBottom: '1rem'
                }}>
                    <Box sx={{display: 'flex', flexDirection: 'row',}}>
                        <Typography sx={{
                            marginRight: '0.25rem',
                        }}>{whoami?.firstName} {whoami?.lastName}:</Typography>
                        <Typography fontWeight={'lighter'}>
                            <>{whoami?.userKey}</>
                        </Typography>
                    </Box>
                    <Typography fontWeight={'lighter'}>{whoami?.email}</Typography>
                    <Typography fontWeight={'lighter'}>{whoami?.phoneNumber?.replace(/(\d{0,1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4' ?? '')}</Typography>
                    <Box sx={{display: 'flex', flexDirection: 'row',}}>
                        <Typography sx={{
                            marginRight: '0.25rem'
                        }}>Created at:</Typography>
                        <Typography fontWeight={'lighter'}> {formatMoment(whoami?.createdAt.toLocaleString())}
                        </Typography>
                    </Box>
                </Box>

                <Typography variant="subtitle1" component="div" align="left"
                            sx={{
                                color: '#6D6D6D',
                                font: 'Roboto',
                                fontWeight: 500,
                                fontSize: '13px',
                                lineHeight: '12px',
                                letterSpacing: '0.05em',
                                paddingLeft: '.75rem',
                                paddingTop: '.75rem',
                                paddingBottom: '.25rem',
                            }}>
                    SUBSCRIPTIONS
                </Typography>
                {SubscriptionCard(userKey)}

                <Typography variant="subtitle1" component="div" align="left"
                            sx={{
                                color: '#6D6D6D',
                                font: 'Roboto',
                                fontWeight: 500,
                                fontSize: '13px',
                                lineHeight: '12px',
                                letterSpacing: '0.05em',
                                paddingLeft: '.75rem',
                                paddingTop: '.75rem',
                                paddingBottom: '.25rem',
                            }}>
                    DEVICES
                </Typography>
                {deviceCard(whoami)}

            <Typography variant="subtitle1" component="div" align="left"
                        sx={{
                            color: '#6D6D6D',
                            font: 'Roboto',
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '12px',
                            letterSpacing: '0.05em',
                            paddingLeft: '.75rem',
                            paddingTop: '.75rem',
                            paddingBottom: '.25rem',
                        }}>
                INSTITUTIONS
            </Typography>
                <UserInstitutionsList userKey={userKey}/>
        </Box>
    )
}

type UserUpdateDialotProps = {
    userKey: number,
    isOpen: boolean,
    handleClose: any,
    whoami: WhoAmIBasic
}
function UserUpdateDialog({ userKey, isOpen, handleClose, whoami }: UserUpdateDialotProps) {

    const [firstName, setFirstName] = useState<String>(whoami?.firstName);
    const [lastName, setLastName] = useState<String>(whoami.lastName);
    const [email, setEmail] = useState<String>(whoami.email);
    const [phoneNumber, setPhoneNumber] = useState<String>(whoami.phoneNumber);
    const [userEditMutation, userEditState] = useMutationAsUser(GQL_UserEdit, {
        userKey,
        variables: {
            firstName,
            lastName,
            email
        }
    })
    const [jediUserEditMutation, jediUserEditState] = useMutationCoordinate(GQL_JediUserEdit, {
        variables: {
            userKey: parseInt(userKey.toString()), // No idea why but this fixes some casting issues
            phoneNumber
        }
    })

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await Promise.all([
                userEditMutation(),
                jediUserEditMutation()
            ]);
            handleClose();
        } catch (e) {
            // eat and render the error
        }
    }

    const loading = userEditState?.loading || jediUserEditState?.loading;

    return <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
            component: 'form',
            onSubmit: handleSubmit,
        }}
    >
        <DialogTitle>Edit Customer</DialogTitle>
        {userEditState?.error &&
            <Alert severity="warning">{userEditState?.error?.message}</Alert>
        }
        {jediUserEditState?.error &&
            <Alert severity="warning">{jediUserEditState?.error?.message}</Alert>
        }
        <DialogContent >
            <TextField
                autoFocus
                required
                margin="dense"
                id="firstName"
                name="firstName"
                label="First Name"
                type="text"
                fullWidth
                variant="standard"
                value={firstName}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setFirstName(e.target.value)}
            />
            <TextField
                autoFocus
                required
                margin="dense"
                id="lastName"
                name="lastName"
                label="Last Name"
                type="text"
                fullWidth
                variant="standard"
                value={lastName}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setLastName(e.target.value)}
            />
            <TextField
                autoFocus
                required
                margin="dense"
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                type="phone"
                fullWidth
                variant="standard"
                value={phoneNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setPhoneNumber(e.target.value)}
            />
            <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                name="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setEmail(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} disabled={loading}>Cancel</Button>
            <Button type="submit" variant='contained' disabled={loading}>Update User</Button>
        </DialogActions>
    </Dialog>
}

function SubscriptionCard(userKey: number){
    const {data} = useQueryAsUser(GQL_SubscriptionPaymentInfoGet, { userKey, variables: { userKey }})
    return(
        <Box sx={{
            border: '1px solid lightgray',
            borderRadius: '8px',
            paddingLeft: '1rem',
            marginLeft: '.75rem',
            marginRight: '.75rem',
            overflowX: 'scroll',
            whiteSpace: 'nowrap',
            marginBottom: '1rem',
        }}>
            {data?.subscriptionPaymentInfoGet?.subscriptions.length !== 0 && data?.subscriptionPaymentInfoGet?.subscriptions.map((subscription: any, index: number) => {
                return (
                      <>
                          <Typography>
                              {subscription.products[0].name}
                          </Typography>
                          <Typography fontWeight={'lighter'} >${subscription.products[0].prices[0].unitAmount / 100} {subscription.products[0].prices[0].displayInterval}</Typography>
                          <Box sx={{display: 'flex', flexDirection: 'row',}}>
                              <Typography sx={{
                                  marginRight: '0.25rem',
                                  fontSize: 'small'
                              }}>SUBSCRIBED SINCE:</Typography>
                              <Typography fontWeight={'lighter'} fontSize={'small'}>
                                  {formatMoment(subscription.createdAt.toLocaleString())}
                              </Typography>
                          </Box>
                          {index < data?.subscriptionPaymentInfoGet?.subscriptions.length - 1 && (
                              <Divider sx={{ my: 1 }} />
                          )}
                      </>
                )}, [])
            }
        </Box>
    );
}

function deviceCard(whoami: WhoAmIWithSubscriptions){
    return (
        <Box sx={{
            borderRadius: '8px',
            border: '1px solid lightgray',
            justifyContent: 'space-between',
            textAlign: 'left',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            marginLeft: '.75rem',
            marginRight: '.75rem',
            marginBottom: '1rem',
        }}>
            <Grid container spacing={0}>
                <Grid item xs={6} textAlign="left">
                    <Typography>{whoami?.metadata?.deviceOS}</Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                    <Typography fontWeight="light">{whoami?.metadata?.deviceOSVersion}</Typography>
                </Grid>
                <Grid item xs={6} textAlign="left">
                    <Typography>App Version</Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                    <Typography fontWeight="light">{whoami?.metadata?.appVersionName}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
}